// situations.js
export const situations = [
    {
      id: 'low-or-no-equity',
      icon: '/path-to-icon1.png',
      header: 'Low or No Equity — EZ',
      content: 'We have creative ways to buy houses that other buyers don’t have.',
      link: '/low-or-no-equity',
    },
    {
      id: 'running-behind-on-payments',
      icon: '/path-to-icon2.png',
      header: 'Running Behind on Payments — EZ',
      content: 'Sell now instead of waiting until the sheriff is knocking on your door and you have a foreclosure on your record.',
      link: '/running-behind-on-payments',
    },
    {
      id: 'notified-of-foreclosure-or-sheriff-sale',
      icon: '/path-to-icon3.png',
      header: 'Notified of Foreclosure or Sheriff Sale — EZ',
      content: 'Don’t be an ostrich with your head in the sand… contact us FAST if this is you. They WILL take your home.',
      link: '/notified-of-foreclosure-or-sheriff-sale',
    },
    {
      id: 'selling-after-death-of-loved-one',
      icon: '/path-to-icon4.png',
      header: 'Selling a Home After the Death of a Loved One — EZ',
      content: 'Sorry for your loss. You are already dealing with enough, let us take this burden off of you.',
      link: '/selling-after-death-of-loved-one',
    },
    {
      id: 'divorce-job-relocation-other-personal-reasons',
      icon: '/path-to-icon5.png',
      header: 'Divorce, Job Relocation, Other Personal Reasons — EZ',
      content: 'You deal with that, we will deal with the house.',
      link: '/divorce-job-relocation-other-personal-reasons',
    },
    {
      id: 'too-much-junk-to-clean-out',
      icon: '/path-to-icon6.png',
      header: 'Too Much Junk to Clean Out — EZ',
      content: 'Just leave it.',
      link: '/too-much-junk-to-clean-out',
    },
    {
      id: 'needs-updating-or-repair',
      icon: '/path-to-icon7.png',
      header: 'Needs Updating or Repair — EZ',
      content: 'We don’t request repairs.',
      link: '/needs-updating-or-repair',
    },
    {
      id: 'need-to-sell-and-stay-in-home-for-a-bit',
      icon: '/path-to-icon8.png',
      header: 'Need to Sell and Stay in Your Home for a Bit — EZ',
      content: 'You can stick around as long as you’d like.',
      link: '/need-to-sell-and-stay-in-home-for-a-bit',
    },
    {
      id: 'need-to-sell-and-buy-home-back-later',
      icon: '/path-to-icon9.png',
      header: 'Need to Sell and Buy Your Home Back Later — EZ',
      content: 'You can rent it back with the option to re-purchase it in the future.',
      link: '/need-to-sell-and-buy-home-back-later',
    },
    {
      id: 'selling-with-agent-didnt-work',
      icon: '/path-to-icon10.png',
      header: 'Selling with an Agent Didn’t or Isn’t Working Out — EZ',
      content: 'Once you don’t have to pay them it’s a ton easier to sell because you keep your money.',
      link: '/selling-with-agent-didnt-work',
    },
    {
      id: 'just-need-quick-simple-transaction',
      icon: '/path-to-icon11.png',
      header: 'Just Need a Quick and Simple Transaction — EZ',
      content: 'That’s almost literally our name… iBuyEZ.com.',
      link: '/just-need-quick-simple-transaction',
    },
    {
      id: 'none-of-these',
      icon: '/path-to-icon-none.png',
      header: 'None of These — EZ',
      content: 'You don’t have to be in a bad spot to want to sell the EZ way.',
      link: '/none-of-these',
    },
  ];
  